import React from "react";
import { useFormik } from "formik";

import { loginUser } from "../../utils/api_services";
import FormFieldLabel from "../../utils/FormFieldLabel";

const Login = ({ setToken }) => {
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      non_field_errors: null
    },
    onSubmit: async (
      values,
      { setSubmitting, setFieldError, setFieldValue }
    ) => {
      const [isValid, responseData] = await loginUser(
        values.username,
        values.password
      );
      setFieldValue("non_field_errors", null, false);
      if (!isValid) {
        if (!responseData) {
          setFieldError("non_field_errors", "Something went wrong.", false);
        } else {
          for (const key in responseData) {
            setFieldError(key, responseData[key]);
          }
        }
        setFieldValue("password", "", false);
      } else {
        setToken(responseData.token);
      }
      setSubmitting(false);
    }
  });

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <img
                src="/assets/images/logo.svg"
                alt="logo"
                className="dispensed-logo"
              />
              <div className="my-1">&nbsp;</div>
              <h6>To get started, please login in with your credentials.</h6>
              <div className="my-1">&nbsp;</div>
              <form onSubmit={formik.handleSubmit}>
                {formik.errors.non_field_errors && (
                  <div>
                    <div className="alert alert-danger">
                      {formik.errors.non_field_errors}
                    </div>
                    <div className="my-1">&nbsp;</div>
                  </div>
                )}
                <div>
                  <FormFieldLabel label="Email Address" isRequired={true} />
                  <div className="my-1">&nbsp;</div>
                  <input
                    id="email"
                    name="username"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    className={
                      "typeform-text-input " +
                      (formik.errors.username ? "is-invalid" : "")
                    }
                    required
                  />
                  {formik.errors.username && formik.touched.username && (
                    <div className="invalid-feedback">
                      {formik.errors.username}
                    </div>
                  )}
                </div>
                <div className="my-3">&nbsp;</div>
                <div>
                  <FormFieldLabel label="Password" isRequired={true} />
                  <div className="my-1">&nbsp;</div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className={
                      "typeform-text-input" +
                      (formik.errors.username ? "is-invalid" : "")
                    }
                    required
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div className="my-3">&nbsp;</div>
                <button
                  type="submit"
                  className="typeform-btn"
                  disabled={formik.isSubmitting}
                >
                  <span className="typeform-btn-text">Login</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
