import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/auth/Login";
import NewApplication from "./pages/auth/NewApplication";

import useToken from "./useToken";

import "./App.css";
import "./Typeform.css";
import TypeForm from "./pages/typeform/Typeform";
import Screensaver from "./pages/utils/Screensaver";

function App() {
  const { token, setToken, clearToken } = useToken();

  function notAuth() {
    return <Login setToken={setToken} />;
  }

  function Auth() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Screensaver />} />
          <Route
            path="/new-application"
            element={
              <NewApplication authToken={token} clearToken={clearToken} />
            }
          />
          <Route path="/typeform" element={<TypeForm />} />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Router>
    );
  }

  return <div className="App">{token ? Auth() : notAuth()}</div>;
}

export default App;
