import { useState } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const stripPhoneNumberForSubmission = (phoneValue) => {
  let strippedValue = phoneValue.trim().replace(/\s+/g, "");

  try {
    const phoneNumber = parsePhoneNumberFromString(strippedValue, "AU");
    if (phoneNumber && phoneNumber.isValid()) {
      strippedValue =
        phoneNumber.getType() === "MOBILE"
          ? phoneNumber.number
          : strippedValue.replace(/[^0-9]/g, "");
    }
    console.log("Stripped phone number for submission:", strippedValue);
    return strippedValue;
  } catch (e) {
    console.error("Invalid phone number for submission:", e);
    return strippedValue; // Return the original stripped value if parsing fails
  }
};

const PhoneNumber = ({ name, formik }) => {
  const [internalValue, setInternalValue] = useState(formik.values[name]);

  const handleBlur = () => {
    let formattedValue = internalValue;

    try {
      const phoneNumber = parsePhoneNumberFromString(internalValue, "AU");
      if (phoneNumber && phoneNumber.isValid()) {
        formattedValue =
          phoneNumber.getType() === "MOBILE"
            ? phoneNumber.formatInternational()
            : phoneNumber.formatNational();
      }
    } catch (e) {
      console.error("Phone number parsing error:", e);
    }

    setInternalValue(formattedValue);
    formik.setFieldValue(name, formattedValue);
    formik.setFieldTouched(name, true);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    formik.setFieldValue(name, newValue);
  };

  const handleKeyDown = (event) => {
    const key = event.key;

    // Allow: backspace, delete, tab, escape, enter, and numbers
    if (
      [
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "Home",
        "End",
        "ArrowLeft",
        "ArrowRight"
      ].indexOf(key) !== -1 ||
      // Allow: Ctrl+A, Ctrl+C, Ctrl+V
      ((event.ctrlKey || event.metaKey) &&
        ["a", "c", "v"].indexOf(key.toLowerCase()) !== -1) ||
      // Allow: +, space
      ["+", " "].indexOf(key) !== -1 ||
      // Allow: numbers
      /[0-9]/.test(key)
    ) {
      // let it happen, don't do anything
      return;
    }

    // Prevent any other input
    event.preventDefault();
  };

  return (
    <input
      name={name}
      type="tel"
      value={internalValue}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onChange={handleChange}
      minLength={8}
      maxLength={15}
      className={`form-control form_control mb-3 ${
        formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
      }`}
      placeholder="+61 400 000 000"
    />
  );
};

export default PhoneNumber;
