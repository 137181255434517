import axios from "axios";

const generateFullURL = (path) => {
  return `${process.env.REACT_APP_BACKEND_URL}${path}`;
};

const defaultHeaders = {
  "Content-Type": "application/json"
};

const getAuthHeaders = (authToken) => {
  return {
    ...defaultHeaders,
    Authorization: `Token ${authToken}`
  };
};

export const defaultException = "Unknown Error";

export async function patientUserRegister(data) {
  return (
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/register/`,
      data,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
  ).data;
}

export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(
      generateFullURL("/api/login/"),
      { username, password },
      {
        headers: { ...defaultHeaders }
      }
    );
    return [true, response.data];
  } catch (exc) {
    return [false, exc.response.data];
  }
};

export const newApplication = async (
  authToken,
  email,
  firstName,
  lastName,
  dob,
  mailingAddress,
  phone,
  gender
) => {
  try {
    const response = await axios.post(
      generateFullURL("/api/new-application/"),
      {
        email: email,
        dob: dob,
        mailing_address: mailingAddress,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        gender: gender
      },
      {
        headers: getAuthHeaders(authToken)
      }
    );
    return [true, response.data];
  } catch (exc) {
    if (exc.response.status === 401 || exc.response.status === 403) {
      return [null, exc.response.data];
    }
    return [false, exc.response.data];
  }
};
