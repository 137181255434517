import React from "react";

const FormFieldLabel = ({ label, isRequired = false }) => {
  return (
    <div>
      <label className="form-label">
        <span>{label}</span>
      </label>
      {isRequired && <span aria-hidden="true">*</span>}
    </div>
  );
};

export default FormFieldLabel;
