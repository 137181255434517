import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "./Overlay.css";

// const TIMEOUT_IDLE_THRESHOLD = 5 * 60 * 1000;

const ResetOverlay = ({ idleTimeoutThreshold, resetTimeout, showButton }) => {
  const navigate = useNavigate();

  const idleTimeoutRef = useRef(null);

  const onIdle = useCallback(() => {
    navigate("/", { replace: true });
    window.location.reload(true);
  }, [navigate]);

  const resetIdleTimeout = useCallback(
    (event) => {
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }

      idleTimeoutRef.current = setTimeout(onIdle, idleTimeoutThreshold);

      return idleTimeoutRef;
    },
    [idleTimeoutRef, idleTimeoutThreshold, onIdle]
  );

  useEffect(() => {
    idleTimeoutRef.current = setTimeout(onIdle, idleTimeoutThreshold);

    window.addEventListener("click", resetIdleTimeout);
    window.addEventListener("touchstart", resetIdleTimeout);
    window.addEventListener("keypress", resetIdleTimeout);

    return () => {
      window.removeEventListener("click", resetIdleTimeout);
      window.removeEventListener("touchstart", resetIdleTimeout);
      window.removeEventListener("keypress", resetIdleTimeout);
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
  });

  return (
    <>
      {showButton && (
        <button className="reset-button" onClick={onIdle}>
          Reset Form
        </button>
      )}
    </>
  );
};

export default ResetOverlay;
