import React, { useEffect, useState } from "react";

import "@typeform/embed/build/css/widget.css";
import { createWidget } from "@typeform/embed";
import { useLocation, useNavigate } from "react-router-dom";

import "./Typeform.css";
import ResetOverlay from "../utils/ResetOverlay";

const TypeForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  let [resetTimeoutCount, setResetTimeoutCount] = useState(0);

  useEffect(() => {
    if (!state) {
      navigate("/", { replace: true });

      window.location.reload(true);
    } else {
      const { formId, initiator_email, token, email, first_name, last_name } =
        state; // Read values passed on state

      const widget = () => {
        createWidget(formId, {
          hidden: {
            initiator_email: initiator_email,
            token: token,
            email: email,
            first_name: first_name,
            last_name: last_name
          },
          container: document.querySelector("#typeform-container"),
          onReady: () => {
            setResetTimeoutCount((s) => s + 1);
          },
          onSubmit: () => {
            setTimeout(() => {
              navigate("/", { replace: true });
              window.location.reload(true);
            }, 3000);
          },
          onQuestionChanged() {
            setResetTimeoutCount((s) => s + 1);
          }
        });
      };
      widget();
    }
  }, [state, navigate]);

  return (
    <>
      <ResetOverlay
        idleTimeoutThreshold={10 * 60 * 1000}
        resetTimeout={resetTimeoutCount}
        showButton={true}
      />
      <div className="typeform-page" id="typeform-container"></div>
    </>
  );
};

export default TypeForm;
