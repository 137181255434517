import React from "react";

const FormFieldError = ({ formik, fieldName }) => {
  return (
    <div>
      {formik.errors[fieldName] && formik.touched[fieldName] && (
        <h6 className="text-danger mt-2">*{formik.errors[fieldName]}</h6>
      )}
    </div>
  );
};

export default FormFieldError;
