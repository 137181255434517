import React, { useEffect } from "react";
import FormFieldError from "./FormFieldError";
import FormFieldLabel from "./FormFieldLabel";

const getAddress = (place) => {
  const address = {
    city: "",
    address1: "",
    state: "",
    country: "",
    postcode: ""
  };

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address.address1 = `${component.long_name} ${address.address1}`;
        break;
      }

      case "route": {
        address.address1 += component.short_name;
        break;
      }

      case "postal_code": {
        address.postcode = `${component.long_name}${address.postcode}`;
        break;
      }

      case "postal_code_suffix": {
        address.postcode = `${address.postcode}-${component.long_name}`;
        break;
      }

      case "locality":
        address.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        address.state = component.short_name;
        break;
      }

      case "country":
        address.country = component.short_name;
        break;

      default:
        break;
    }
  }
  return address;
};

const AutoCompleteAddress = ({
  setMailingAddress,
  fieldName,
  formik,
  classNames
}) => {
  useEffect(() => {
    let autocomplete;

    const handlePlaceSelect = () => {
      const place = autocomplete.getPlace();
      const fullAddress = document.getElementById(fieldName).value;

      setMailingAddress({
        ...getAddress(place),
        full_address: fullAddress
      });

      formik.setFieldValue(fieldName, fullAddress);
    };

    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(fieldName),
      {
        componentRestrictions: { country: ["au"] },
        fields: ["address_components", "geometry"],
        types: ["address"]
      }
    );

    autocomplete.addListener("place_changed", handlePlaceSelect);
  }, [formik, fieldName, setMailingAddress]);

  return (
    <div>
      <FormFieldLabel label="Address" isRequired={true} />
      <input
        id={fieldName}
        name={fieldName}
        onChange={formik.handleChange}
        value={formik.values[fieldName]}
        type="text"
        className={
          "form-control form_control " +
          classNames +
          " " +
          (formik.errors[fieldName] ? "is-invalid" : "")
        }
        required
      />
      <FormFieldError formik={formik} fieldName={fieldName} />
    </div>
  );
};

AutoCompleteAddress.defaultProps = {
  fieldName: "mailing_address_placeholder",
  classNames: ""
};

export default AutoCompleteAddress;
